import { Component, Input, OnInit } from '@angular/core';
import {
  XpoBoardApi,
  XpoBoardOptions,
  XpoBoardReadyEvent,
  XpoBoardState,
  XpoNumberFilterComponent,
  XpoStringFilterComponent,
} from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardView, XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import {
  CellEditionErrorCellRendererComponent,
  DatepickerEditorComponent,
  formatChecker,
  XpoInlineEditingBase,
} from '@xpo-ltl/ngx-ltl-grid';
import { ActionCd } from '@xpo-ltl/sdk-common';
import { PriceRuleAmcOverride } from '@xpo-ltl/sdk-dynamicpricing';
import { AgGridEvent, ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import moment from 'moment';
import { ReplaySubject } from 'rxjs';
import { DecimalEditorComponent } from '../../../../lib/ag-grid/cell-editors/decimal-editor/decimal-editor.component';
import { NumericEditorComponent } from '../../../../lib/ag-grid/cell-editors/numeric-editor/numeric-editor.component';
import { AbsoluteMinimumChargeTypeCd } from '../../../shared/enums';
import { SearchAccessorialCharge } from '../../../shared/models/search-accessorial-charge';
import { AmcOverrideDataSourceService } from '../../../shared/services/absolute-minimum-charge-override/amc-override-data-source.service';
import { AmcOverrideService } from '../../../shared/services/absolute-minimum-charge-override/amc-override.service';
import { AbsoluteMinimumChargeService } from '../../../shared/services/absolute-minimum-charge/absolute-minimum-charge.service';
import { ConstantsService } from '../../../shared/services/constants/constants.service';
import { DynamicPricingStorageService } from '../../../shared/services/dynamic-pricing/dynamic-pricing-storage.service';
import { SelectCellEditorComponent } from '../select-cell-editor/select-cell-editor.component';
import { AmcOverrideEnum } from './amc-override-grid.enum';
import { UiIconActionComponent } from './ui-icon-action/ui-icon-action.component';

@Component({
  selector: 'app-amc-override',
  templateUrl: './amc-override.component.html',
  styleUrls: ['./amc-override.component.scss'],
})
export class AmcOverrideComponent extends XpoInlineEditingBase implements OnInit {
  isEditing: boolean;
  keyField = 'amcOverrideSequenceNbr';
  @Input() editable: boolean;
  @Input() ruleId;
  stateChange$ = new ReplaySubject<XpoBoardState>(1);
  viewTemplates: XpoAgGridBoardViewTemplate[];
  views: XpoAgGridBoardView[];
  customGridOptions: GridOptions;
  gridApi: GridApi;
  colApi: ColumnApi;
  gridData: any;
  boardOptions: XpoBoardOptions = {
    preloadViewData: false,
    suppressRecordCounts: true,
    suppressGridSettingsPopover: true,
    suppressViewSwitcher: false,
  };
  amcOverride = new PriceRuleAmcOverride();
  typeList = [];
  getDefaultAmcInd: boolean = false;
  private boardApi: XpoBoardApi;
  constructor(
    public dataSource: AmcOverrideDataSourceService,
    private constants: ConstantsService,
    private amcOverrideService: AmcOverrideService,
    private absoluteMinimumChargeService: AbsoluteMinimumChargeService,
    private dynamicPricingStorageService: DynamicPricingStorageService
  ) {
    super();
    Object.values(AbsoluteMinimumChargeTypeCd).forEach((d) => {
      this.typeList.push({
        key: d,
        value: d,
      });
    });
  }

  private editableField(params): boolean {
    if (this.editable) {
      if (
        params.data.typeCd !== AbsoluteMinimumChargeTypeCd.miles &&
        (params.colDef.field === AmcOverrideEnum.minMilesNbr || params.colDef.field === AmcOverrideEnum.maxMilesNbr)
      ) {
        return false;
      }
      if (params.colDef.field !== AmcOverrideEnum.chargeAmount) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  ngOnInit(): void {
    this.customGridOptions = {
      enableCellTextSelection: true,
      pagination: false,
      singleClickEdit: true,
      frameworkComponents: {
        xpoStringFilterComponent: XpoStringFilterComponent,
        xpoNumberFilterComponent: XpoNumberFilterComponent,
        numericEditor: NumericEditorComponent,
        decimalEditor: DecimalEditorComponent,
        cellEditionError: CellEditionErrorCellRendererComponent,
        iconActionComponent: UiIconActionComponent,
        datepicker: DatepickerEditorComponent,
        selectEditor: SelectCellEditorComponent,
      },
      isRowSelectable: () => {
        return false;
      },
      defaultColDef: {
        suppressMenu: true,
        sortable: false,
        resizable: true,
        filter: false,
        editable: (params) => {
          return this.editableField(params);
        },
        cellRenderer: 'cellEditionError',
        cellRendererParams: (params) => {
          return {
            error: params.data && this.isCellInvalid(params.data[this.keyField], params.colDef.field),
          };
        },
        cellClassRules: {
          'xpo-AgGrid-editableCell--error': (params) =>
            params.data && this.isCellInvalid(params.data[this.keyField], params.colDef.field),
        },
        valueSetter: (params) => {
          this.handleValueSetter(params, this.keyField);
          return true;
        },
        tooltipValueGetter: (params) => {
          return params.data && this.getErrorDescription(params, params.colDef.headerTooltip);
        },
      },
      onCellEditingStopped: (params) => {
        const amc = params.data;
        if (params.colDef.field === AmcOverrideEnum.typeCd) {
          this.changeAmcType(amc);
        }
        if (params.oldValue !== params.newValue && amc.priceRuleHeaderId) {
          amc.listActionCd = ActionCd.UPDATE;
        }
        if (!amc.listActionCd) {
          amc.listActionCd = ActionCd.ADD;
        }
      },
      onCellEditingStarted: () => {
        this.gridData = this.dataSource.list;
        this.onStartEditing();
      },
    };
    this.viewTemplates = this.getBoardViewTemplates();
    this.views = this.getBoardViews(this.viewTemplates[0]);

    this.amcOverrideService.amcList$.subscribe((d) => {
      if (d && d.length) {
        this.getDefaultAmcInd = !d.find((result) => {
          return result.listActionCd !== ActionCd.DELETE;
        });
      } else {
        this.getDefaultAmcInd = true;
      }
      this.dataSource.refresh();
    });
  }

  private getTypeList() {
    return { options: this.typeList };
  }

  private changeAmcType(amc): void {
    if (amc.typeCd !== AbsoluteMinimumChargeTypeCd.miles) {
      amc.minMilesNbr = null;
      amc.maxMilesNbr = null;
      this.dataSource.refresh();
    }
  }

  onBoardReady(event: XpoBoardReadyEvent): void {
    this.boardApi = event.boardApi;
  }

  onGridReady(event: AgGridEvent) {
    this.gridApi = event.api;
    this.colApi = event.columnApi;
    const newState = this.dataSource.currentState;
    this.gridData = newState && newState.data && newState.data.consumerData;
  }

  getBoardViewTemplates(): Array<XpoAgGridBoardViewTemplate> {
    return [
      new XpoAgGridBoardViewTemplate({
        id: '1',
        name: 'AMC',
        keyField: '1',
        availableColumns: this.getColumnsForGrid(),
      }),
    ];
  }

  private getBoardViews(viewTemplate: XpoAgGridBoardViewTemplate): XpoAgGridBoardView[] {
    return [
      viewTemplate.createView({
        id: 'AMC',
        name: this.getBoardName(),
      }),
    ];
  }

  private getBoardName() {
    return 'Override';
  }

  getColumnsForGrid() {
    const numberValidator = (fieldName, params: any, maxNumber: any) => {
      const twoDecimalRegex = /^\d+(\.\d{0,2})?$/;
      const value = params.editedRow.columns[params.column];
      const isValid: formatChecker = {
        fail: isNaN(value) || parseFloat(value) < 0 || parseFloat(value) > maxNumber || value === '',
        errorDescription: 'Should be between 0 and ' + maxNumber,
      };
      if (!isValid.fail && !twoDecimalRegex.test(value)) {
        isValid.errorDescription = 'Should have 2 decimals max';
        isValid.fail = true;
      }
      this.amcOverrideService.setErrorMessage(
        fieldName + params.editedRow.id,
        isValid.fail ? isValid.errorDescription : null
      );
      return isValid;
    };
    const enabled = (params) => {
      if (params.data && this.editableField(params)) {
        // mark police cells as red
        return { backgroundColor: '#FFFFFF' };
      }
      return { backgroundColor: '#F3F3F3' };
    };
    return [
      {
        headerName: '',
        field: 'amcOverrideSequenceNbr',
        cellRenderer: 'iconActionComponent',
        cellClassRules: {
          'xpo-AgGrid-editableCell': () => false,
        },
        cellRendererParams: () => {
          return {
            isExistingRule: this.editable,
          };
        },
        width: 70,
        pinned: 'left',
        editable: false,
      },
      {
        headerName: AmcOverrideEnum.typeCdFieldName,
        field: AmcOverrideEnum.typeCd,
        cellEditor: 'selectEditor',
        cellEditorParams: this.getTypeList.bind(this),
        width: 150,
        cellStyle: enabled,
      },
      {
        headerName: AmcOverrideEnum.chargeAmountFieldName,
        field: AmcOverrideEnum.chargeAmount,
        width: 150,
        cellEditor: 'decimalEditor',
        cellConfig: {
          allowEmpty: false,
          formatValidator: (params: any) => {
            return numberValidator(AmcOverrideEnum.chargeAmountFieldName, params, 999999999.99);
          },
        },
        cellStyle: enabled,
      },
      {
        headerName: AmcOverrideEnum.minMilesNbrFieldName,
        field: AmcOverrideEnum.minMilesNbr,
        width: 150,
        cellRenderer: (params) => {
          return params.value === 0 && params.data && params.data.typeCd !== AbsoluteMinimumChargeTypeCd.miles
            ? null
            : params.value;
        },
        cellConfig: {
          allowEmpty: false,
          formatValidator: (params: any) => {
            return numberValidator(AmcOverrideEnum.minMilesNbrFieldName, params, 9999);
          },
        },
        cellStyle: enabled,
      },
      {
        headerName: AmcOverrideEnum.maxMilesNbrFieldName,
        field: AmcOverrideEnum.maxMilesNbr,
        width: 150,
        cellRenderer: (params) => {
          return params.value === 0 && params.data && params.data.typeCd !== AbsoluteMinimumChargeTypeCd.miles
            ? null
            : params.value;
        },
        cellConfig: {
          allowEmpty: false,
          formatValidator: (params: any) => {
            return numberValidator(AmcOverrideEnum.maxMilesNbrFieldName, params, 9999);
          },
        },
        cellStyle: enabled,
      },
      {
        headerName: AmcOverrideEnum.effectiveDateFieldName,
        field: AmcOverrideEnum.effectiveDate,
        width: 100,
        editable: false,
        cellStyle: { backgroundColor: '#F3F3F3' },
        cellRenderer: (params) => {
          return params.value ? moment(new Date(`${params.value} `)).format(this.constants.DATE_FORMAT_MOMENT) : null;
        },
      },
      {
        headerName: AmcOverrideEnum.expiryDateFieldName,
        field: AmcOverrideEnum.expiryDate,
        width: 95,
        editable: false,
        cellStyle: { backgroundColor: '#F3F3F3' },
        cellRenderer: (params) => {
          return params.value ? moment(new Date(`${params.value} `)).format(this.constants.DATE_FORMAT_MOMENT) : null;
        },
      },
    ];
  }

  getTableSize() {
    const size = 150 + this.dataSource.currentState.visibleRows * 30;
    return size > 270 ? 270 : size;
  }

  addAmc() {
    this.amcOverrideService.addAmcOverride();
    this.dataSource.refresh();
  }

  unfocus() {
    if (this.gridApi.getFocusedCell()) {
      this.gridApi.setFocusedCell(0, 'amcOverrideSequenceNbr');
    }
  }

  deleteAmc(): void {
    this.amcOverrideService.deleteAll();
  }

  getDefaultAmc(): void {
    const params = new SearchAccessorialCharge();
    params.beginDate = moment()
      .startOf('day')
      .toDate();
    this.dynamicPricingStorageService.setAccessorialChargeListParams(params);
    this.absoluteMinimumChargeService.listAbsoluteMinimumCharges().subscribe((result: any[]) => {
      result.sort((f, s) => f.typeCd.localeCompare(s.typeCd) || f.minimumMilesNbr - s.minimumMilesNbr);
      this.amcOverrideService.replaceAmcList(
        result.map((amcResult, index) => {
          return {
            chargeAmount: amcResult.chargeAmount,
            effectiveDate: amcResult.effectiveDate,
            expiryDate: amcResult.expiryDate,
            typeCd: amcResult.typeCd,
            minMilesNbr: amcResult.minimumMilesNbr,
            maxMilesNbr: amcResult.maxMilesNbr,
            amcOverrideSequenceNbr: index + 1,
            listActionCd: ActionCd.ADD,
          };
        })
      );
      this.dataSource.refresh();
    });
  }
}
